import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';

import './RankList.scss';

import ScrollLoad from '-/common/js/scroll';
import Loading from '../Loading/Loading';
import { changeActive, changeLoading, loadArticleList } from '../store/dispatch/rankList';
import { Url } from 'lif-lib';
import RowArticle from './RowArticle/RowArticle';

class RankList extends Component {
    constructor (props) {
        super(props);

        this.rightContainer = React.createRef();
        this.bookCover = React.createRef();
    }

    render () {
        const { articleList, pageEnd, loading, rankType, active } = this.props;
        return (
            <div className="rank-list">
                <div className="rank-list-left">
                    {rankType.map((v) => {
                        return (
                            <div onClick={this.changeActive.bind(this, v.id)} key={v.id} className={'rank-item ' + (active === v.id ? 'active' : '')}>{v.name}</div>
                        );
                    })}
                </div>
                <div className="rank-list-right" ref={this.rightContainer}>
                    <div className="right-container">
                        {articleList.map((v) => {
                            return (<RowArticle key={v.id} article={v}/>);
                        })}
                        <Loading pageEnd={pageEnd} loading={loading}/>
                    </div>
                </div>
            </div>
        );
    }

    changeActive (id) {
        if (this.props.active !== id) {
            this.rightContainer.current.scrollTop = 0;
            this.props.changeActive(id);
        }
    }

    componentDidMount () {
        if (this.scroll) {
            return;
        }

        const nextPage = this.props.page + 1;
        const url = new Url(window.location.href);

        const active = url.get('type') ? url.get('type') : 1;

        this.scroll = new ScrollLoad({
            scrollArea: this.rightContainer.current,
            loadOffset: 30,
            initPage: nextPage,
            autoLoad: nextPage === 1,
            loadFun: (currentPage, me) => {
                this.props.changeLoading(true);
                this.props.loadArticleList(active, currentPage, me);
            }
        });

        if (this.props.pageEnd) {
            this.scroll.lock();
        }
    }

    componentWillUnmount () {
        this.scroll && this.scroll.removeScrollEvent();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        if (this.props.active !== nextProps.active) {
            this.scroll && this.scroll.removeScrollEvent();
            this.scroll = new ScrollLoad({
                scrollArea: this.rightContainer.current,
                loadOffset: 30,
                initPage: 1,
                loadFun: (currentPage, me) => {
                    this.props.changeLoading(true);
                    this.props.loadArticleList(nextProps.active, currentPage, me);
                }
            });
        }
    }
}

const stateMap = (state) => {
    return {
        rankType: state.rankListReducer.rankType,
        loading: state.rankListReducer.loading,
        hasLoad: state.rankListReducer.hasLoad,
        articleList: state.rankListReducer.articleList,
        pageEnd: state.rankListReducer.pageEnd,
        active: state.rankListReducer.active,
        page: state.rankListReducer.page
    };
};

const eventMap = (dispatch) => {
    return {
        changeActive (id) {
            dispatch(changeActive(id));
        },
        changeLoading (status) {
            dispatch(changeLoading(status));
        },
        loadArticleList (rankType, page, scroll) {
            dispatch(loadArticleList(rankType, page, scroll));
        }
    };
};

RankList.propTypes = {
    changeActive: PropType.func.isRequired,
    changeLoading: PropType.func.isRequired,
    hasLoad: PropType.bool.isRequired,
    articleList: PropType.array.isRequired,
    loadArticleList: PropType.func.isRequired,
    pageEnd: PropType.bool.isRequired,
    loading: PropType.bool.isRequired,
    rankType: PropType.array.isRequired,
    active: PropType.number.isRequired,
    page: PropType.number.isRequired
};

export default connect(stateMap, eventMap)(RankList);
