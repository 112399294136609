import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';

import './BookType.scss';
import { changeActive, changeLoading, loadArticleList, loadTypeList } from '../store/dispatch/bookType';
import SectionArticleItem from '../BookSectionList/SectionArticleItem/SectionArticleItem';

import ScrollLoad from '-/common/js/scroll';
import Loading from '../Loading/Loading';
import { Url } from 'lif-lib';

class BookType extends Component {
    constructor (props) {
        super(props);

        const url = new Url(window.location.href);
        const queryCategory = url.get('category');
        let categoryActive = null;
        if (queryCategory) {
            if (queryCategory === '1' || queryCategory === '2') {
                categoryActive = parseInt(queryCategory);
            }
        }

        this.state = {
            categoryActive: categoryActive
        };

        this.scroll = null;
    }

    componentDidMount () {
        if (this.scroll) {
            // 存在事件
            return;
        }

        // 不存在事件

        const nextPage = this.props.page + 1;

        const { category, sort, status, method } = this.props.typeList;

        if (!this.props.hasLoad) {
            this.props.loadTypeList();
        }

        const categoryActive = this.state.categoryActive ? this.state.categoryActive : category.active;

        this.scroll = new ScrollLoad({
            scrollArea: window,
            loadOffset: 30,
            initPage: nextPage,
            autoLoad: nextPage === 1,
            loadFun: (currentPage, me) => {
                this.props.changeLoading(true);
                this.props.loadArticleList(categoryActive, sort.active, method.active, status.active, currentPage, me);
            }
        });

        if (this.props.pageEnd) {
            this.scroll.lock();
        }
    }

    componentWillUnmount () {
        this.scroll && this.scroll.removeScrollEvent();
    }

    render () {
        const { articleList, pageEnd, loading } = this.props;
        const ok = this.props.page >= 1;

        return (
            <Fragment>
                { !ok
                    ? <div className="type-full-mask">
                        <Loading pageEnd={false} loading={true}/>
                    </div>
                    : ''}
                <div className="book-type">
                    <div className="book-type-options">
                        {this.state.categoryActive
                            ? ''
                            : <div className="option-category">
                                {this.props.category.data.map((v) => {
                                    return (
                                        <div onClick={this.changeActive.bind(this, 'category', v.id)}
                                            className={'option-item ' + (this.props.category.active === v.id ? 'active' : '')} key={v.id}>{v.name}</div>
                                    );
                                })}
                            </div>
                        }
                        <div className="option-sort">
                            {this.renderSortOptions()}
                        </div>
                        <div className="option-status">
                            {this.props.status.data.map((v) => {
                                return (
                                    <div onClick={this.changeActive.bind(this, 'status', v.id)}
                                        className={'option-item ' + (this.props.status.active === v.id ? 'active' : '')} key={v.id}>{v.name}</div>
                                );
                            })}
                        </div>
                        <div className="option-method">
                            {this.props.method.data.map((v) => {
                                return (
                                    <div onClick={this.changeActive.bind(this, 'method', v.id)}
                                        className={'option-item ' + (this.props.method.active === v.id ? 'active' : '')} key={v.id}>{v.name}</div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="article-list">
                        {articleList.map((v) => {
                            return <SectionArticleItem key={v.id} article={v} style={{}}/>;
                        })}
                        <Loading pageEnd={pageEnd} loading={loading}/>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderSortOptions () {
        let data;

        if (this.props.category.active === 1) {
            data = this.props.sort.data.male;
        } else {
            data = this.props.sort.data.female;
        }

        return data.map((v) => {
            return (
                <div onClick={this.changeActive.bind(this, 'sort', v.id)}
                    className={'option-item ' + (this.props.sort.active === v.id ? 'active' : '')} key={v.id}>{v.name}</div>
            );
        });
    }

    changeActive (action, id) {
        this.props.changeActive(action, id);
    }

    getTypeListPropsSign (typeList) {
        const { category, sort, status, method } = typeList;
        // 如果通关url传递的category, 则不能监听category.active变化
        if (this.state.categoryActive) {
            return `${sort.active}-${status.active}-${method.active}`;
        }
        return `${category.active}-${sort.active}-${status.active}-${method.active}`;
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        const oldSignStr = this.getTypeListPropsSign(this.props.typeList);
        const newSignStr = this.getTypeListPropsSign(nextProps.typeList);

        if (newSignStr !== oldSignStr) {
            const { category, sort, status, method } = nextProps.typeList;
            this.scroll && this.scroll.removeScrollEvent();
            this.scroll = new ScrollLoad({
                scrollArea: window,
                loadOffset: 30,
                initPage: 1,
                loadFun: (currentPage, me) => {
                    this.props.changeLoading(true);
                    this.props.loadArticleList(category.active, sort.active, method.active, status.active, currentPage, me);
                }
            });
        }
    }
}

const stateMap = (state) => {
    return {
        method: state.bookTypeReducer.typeList.method,
        category: state.bookTypeReducer.typeList.category,
        status: state.bookTypeReducer.typeList.status,
        sort: state.bookTypeReducer.typeList.sort,
        loading: state.bookTypeReducer.loading,
        hasLoad: state.bookTypeReducer.hasLoad,
        articleList: state.bookTypeReducer.articleList,
        pageEnd: state.bookTypeReducer.pageEnd,
        typeList: state.bookTypeReducer.typeList,
        page: state.bookTypeReducer.page
    };
};

const eventMap = (dispatch) => {
    return {
        changeActive (action, id) {
            dispatch(changeActive(action, id));
        },
        changeLoading (status) {
            dispatch(changeLoading(status));
        },
        loadTypeList () {
            dispatch(loadTypeList());
        },
        loadArticleList (category, sort, method, status, page, scroll) {
            dispatch(loadArticleList(category, sort, method, status, page, scroll));
        }
    };
};

BookType.propTypes = {
    method: PropType.object.isRequired,
    status: PropType.object.isRequired,
    sort: PropType.object.isRequired,
    category: PropType.object.isRequired,
    changeActive: PropType.func.isRequired,
    changeLoading: PropType.func.isRequired,
    loadTypeList: PropType.func.isRequired,
    hasLoad: PropType.bool.isRequired,
    articleList: PropType.array.isRequired,
    typeList: PropType.object.isRequired,
    loadArticleList: PropType.func.isRequired,
    pageEnd: PropType.bool.isRequired,
    loading: PropType.bool.isRequired,
    page: PropType.number.isRequired
};

export default connect(stateMap, eventMap)(BookType);
