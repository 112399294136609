import {
    HOT_UPDATE__CHANGE_LOADING,
    HOT_UPDATE__LOAD_ARTICLE_LIST
} from '../actionType';
import axios from 'axios';
import config from '../../../config';

export const changeLoading = (status) => {
    return {
        type: HOT_UPDATE__CHANGE_LOADING,
        data: status
    };
};

export const loadArticleList = (page, scroll) => {
    return async (dispatch) => {
        try {
            const reps = await axios.post(config.getOrderListUrl, {
                page: page,
                sort: -1,
                method: 2,
                fullFlag: -1,
                category: -1
            });

            const { code, data, message } = reps.data;

            if (code !== 0) {
                console.error(message);
                return;
            }

            let pageEnd;

            if (data.length === 20) {
                scroll.ready();
                pageEnd = false;
            } else {
                scroll.lock();
                pageEnd = true;
            }

            dispatch({
                type: HOT_UPDATE__LOAD_ARTICLE_LIST,
                data: data,
                page: page,
                pageEnd: pageEnd
            });
        } catch (e) {
            console.error(e);
        }
    };
};
