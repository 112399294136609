import {
    BOOK_SECTION_LIST__CHANGE_PAGE_END,
    BOOK_SECTION_LIST__CHANGE_LOADING,
    BOOK_SECTION_LIST__CHANGE_SECTION_ACTIVE, BOOK_SECTION_LIST__CHANGE_SECTION_DATA,
    BOOK_SECTION_LIST__PUSH_SECTION_LIST_DATA
} from '../../store/actionType';

const initState = {
    1: {
        sectionList: [],
        pageEnd: false,
        nextPage: 1,
        id: 1,
        pageLoading: false,
        loading: true
    },
    2: {
        sectionList: [],
        pageEnd: false,
        nextPage: 1,
        id: 2,
        pageLoading: false,
        loading: true
    },
    3: {
        sectionList: [],
        pageEnd: false,
        nextPage: 1,
        id: 3,
        pageLoading: false,
        loading: true
    },
    4: {
        sectionList: [],
        pageEnd: false,
        nextPage: 1,
        id: 4,
        pageLoading: false,
        loading: true
    }
};

const changeSectionActive = (state, action) => {
    state[action.sectionType].sectionList[action.index].btn_active = action.status;
    return state;
};

const pushSectionListData = (state, action) => {
    const currentSection = state[action.sectionType];
    // if (action.page === 1) {
    //     state[action.sectionType] = {
    //         sectionList: [],
    //         pageEnd: false,
    //         nextPage: 1,
    //         id: action.sectionType
    //     };
    // }
    if (currentSection.loading) {
        currentSection.loading = false;
    }
    currentSection.pageLoading = false;
    currentSection.nextPage = action.page;
    currentSection.pageEnd = action.pageEnd;
    currentSection.sectionList = currentSection.sectionList.concat(action.data);

    if (action.loadMode === 2 && currentSection.sectionList.length > 0) {
        const lastSection = currentSection.sectionList[currentSection.sectionList.length - 1];
        if (lastSection !== undefined) {
            lastSection.articleList = lastSection.articleList.concat(action.extData);
        }
        console.log(lastSection);
    }

    console.log(state[action.sectionType]);
    return state;
};

const changeLoading = (state, action) => {
    state[action.sectionType].pageLoading = action.loading;
    return state;
};

const changePageEnd = (state, action) => {
    state[action.sectionType].pageEnd = action.pageEnd;
    return state;
};

const changeSectionData = (state, action) => {
    state[action.sectionType].sectionList[action.index].btn_active = action.active;
    state[action.sectionType].sectionList[action.index].articleList = action.articleList;
    return state;
};

export default (state = initState, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
    case BOOK_SECTION_LIST__CHANGE_SECTION_ACTIVE:
        return changeSectionActive(newState, action);
    case BOOK_SECTION_LIST__PUSH_SECTION_LIST_DATA:
        return pushSectionListData(newState, action);
    case BOOK_SECTION_LIST__CHANGE_LOADING:
        return changeLoading(newState, action);
    case BOOK_SECTION_LIST__CHANGE_PAGE_END:
        return changePageEnd(newState, action);
    case BOOK_SECTION_LIST__CHANGE_SECTION_DATA:
        return changeSectionData(newState, action);
    default:
        return state;
    }
};
