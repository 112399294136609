import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './Banner.scss';
import { pushBannerData } from '../store/dispatch/banner';

import PhoneSlider from 'lif-phone-slider';
import * as bridge from '../../bridge';

class Banner extends Component {
    constructor (props) {
        super(props);

        this.currentItem = this.props.titleBar.find((item) => {
            if (item.id === this.props.type) {
                return item;
            }
            return null;
        });

        this.slider = null;
    }

    render () {
        return (
            <div className="phone-slider banner">
            </div>
        );
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        this.renderPhoneSlider();
    }

    renderPhoneSlider () {
        if (this.slider === null && this.props.hasLoad && this.props.bannerList.length > 0) {
            let { bannerList } = this.props;
            bannerList = bannerList.map((val) => {
                return {
                    img: val.img,
                    click: Banner.handleEvent.bind(this, val.href)
                };
            });
            this.slider = new PhoneSlider({
                target: '.phone-slider',
                fun: 'ease',
                moveTime: 1,
                runTime: 3,
                list: bannerList,
                btnClick: false
            });
        }
    }

    static handleEvent (data) {
        bridge.sendEvent(data);
    }

    componentDidMount () {
        this.props.pushBannerData(this.currentItem.id);
        this.renderPhoneSlider();
    }
}

const stateMap = (state) => {
    return {
        bannerList: state.bannerReducer.bannerList,
        hasLoad: state.bannerReducer.hasLoad,
        titleBar: state.titleBarReducer.barList
    };
};

const eventMap = (dispatch) => {
    return {
        pushBannerData (type) {
            dispatch(pushBannerData(type));
        }
    };
};

Banner.propTypes = {
    bannerList: PropTypes.array.isRequired,
    pushBannerData: PropTypes.func.isRequired,
    hasLoad: PropTypes.bool.isRequired,
    titleBar: PropTypes.array.isRequired,
    type: PropTypes.number.isRequired
};

export default connect(stateMap, eventMap)(Banner);
