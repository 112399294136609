import {
    BOOK_TYPE__CHANGE_ACTIVE,
    BOOK_TYPE__CHANGE_LOADING,
    BOOK_TYPE__LOAD_ARTICLE_LIST,
    BOOK_TYPE__LOAD_TYPE_LIST
} from '../../store/actionType';

const initState = {
    typeList: {
        category: {
            data: [{
                name: '男生',
                id: 1
            }, {
                name: '女生',
                id: 2
            }],
            active: 1
        },
        sort: {
            data: {
                male: [{
                    name: '全部',
                    id: -1
                }],
                female: [{
                    name: '全部',
                    id: -1
                }]
            },
            active: -1
        },
        status: {
            data: [{
                name: '全部',
                id: -1
            }, {
                name: '连载',
                id: 2
            }, {
                name: '完结',
                id: 1
            }],
            active: -1
        },
        method: {
            data: [{
                name: '人气',
                id: 1
            }, {
                name: '更新',
                id: 2
            }, {
                name: '字数',
                id: 3
            }, {
                name: '收藏量',
                id: 4
            }],
            active: 1
        }
    },
    loading: false,
    hasLoad: false,
    articleList: [],
    pageEnd: false,
    page: 0
};

const loadTypeList = (state, action) => {
    state.typeList.sort.data = {
        male: [{
            name: '全部',
            id: -1
        }],
        female: [{
            name: '全部',
            id: -1
        }]
    };
    action.data.forEach((val) => {
        if (val.category === 1) {
            state.typeList.sort.data.male.push({
                name: val.name,
                id: val.id
            });
        } else if (val.category === 2) {
            state.typeList.sort.data.female.push({
                name: val.name,
                id: val.id
            });
        }
    });
    return state;
};

const changeActive = (state, action) => {
    const old = state.typeList[action.action].active;
    state.typeList[action.action].active = action.id;
    if (action.action === 'category' && old !== action.id) {
        state.typeList.sort.active = -1;
    }
    return state;
};

const changeLoading = (state, action) => {
    state.loading = action.data;
    return state;
};

const loadArticleList = (state, action) => {
    if (action.page === 1) {
        state.articleList = [];
    }
    state.articleList = state.articleList.concat(action.data);
    state.hasLoad = true;
    state.loading = false;
    state.pageEnd = action.pageEnd;
    state.page = action.page;
    state.typeList.category.active = action.category;
    return state;
};

export default (state = initState, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
    case BOOK_TYPE__LOAD_TYPE_LIST:
        return loadTypeList(newState, action);
    case BOOK_TYPE__CHANGE_ACTIVE:
        return changeActive(newState, action);
    case BOOK_TYPE__CHANGE_LOADING:
        return changeLoading(newState, action);
    case BOOK_TYPE__LOAD_ARTICLE_LIST:
        return loadArticleList(newState, action);
    default:
        return state;
    }
};
