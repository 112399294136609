import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as bridge from '../../bridge';

import './ItemList.scss';

class ItemList extends Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <div className="item-list">
                {
                    this.props.itemList.map((item) => {
                        return (
                            <a key={item.id} className="item" onClick={ItemList.handleClick.bind(this, item)}>
                                <img className="item-icon" src={item.icon} alt={item.name}/>
                                <div className="item-name">{item.name}</div>
                            </a>
                        );
                    })
                }

            </div>
        );
    }

    static handleClick (item) {
        let { href, name, id } = item;
        if (id === 1) {
            // open footer NavBar
            if (bridge.footerNavBar('category')) {
                return;
            }
        }
        href = window.location.origin + '/index.html' + href;
        bridge.openWindow(href, name, 1, 0);
    }
}

const stateMap = (state) => {
    return {
        itemList: state.itemListReducer.itemList
    };
};

const eventMap = (dispatch) => {
    return {};
};

ItemList.propTypes = {
    itemList: PropTypes.array.isRequired
};

export default connect(stateMap, eventMap)(ItemList);
