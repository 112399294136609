import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SectionArticleItem from '../BookSectionList/SectionArticleItem/SectionArticleItem';
import RowArticleItem from '../BookSectionList/RowArticleItem/RowArticleItem';

import './BookSection.scss';

class BookSection extends Component {
    constructor (props) {
        super(props);
    }

    componentDidMount () {

    }

    componentWillUnmount () {
    }

    render () {
        const { section, onClick } = this.props;
        return (
            <div className="book-section" key={section.id}>
                <div className="section-header clear-fix">
                    <div className="section-title">{section.title}</div>
                    {onClick != null
                        ? <div className="section-replace-btn" onClick={onClick}>
                            <span className={'iconfont ' + (section.btn_active ? 'active' : '')}>&#xe60f;</span>
                            换一换
                        </div>
                        : ''}
                </div>
                <div className="section-body clear-fix">
                    {section.articleList.map((article, index) => {
                        if (section.templateId === 1) {
                            return <SectionArticleItem key={article.id} article={article} style={{}}/>;
                        } else if (section.templateId === 3) {
                            if (index === 0) {
                                return <SectionArticleItem key={article.id} article={article} style={{ border: 'none' }}/>;
                            } else {
                                return <div key={article.id} className="row-template-style"><RowArticleItem
                                    article={article}/></div>;
                            }
                        }
                        return <div key={article.id} className="row-template-style"><RowArticleItem article={article}/>
                        </div>;
                    })}
                </div>
                {/* <div className="section-more iconfont">查看更多</div> */}
            </div>
        );
    }
}

const stateMap = (state) => {
    return {};
};

const eventMap = (dispatch) => {
    return {};
};

BookSection.propTypes = {
    section: PropTypes.object.isRequired,
    onClick: PropTypes.func
};

export default connect(stateMap, eventMap)(BookSection);
