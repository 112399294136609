/**
 * 命名规范:
 *      组件名 + __ + 操作
 */
const TITLE_BAR__CHANGE_BAR = 'TITLE_BAR__CHANGE_BAR';

const BOOK_SECTION_LIST__CHANGE_SECTION_ACTIVE = 'BOOK_SECTION_LIST__CHANGE_SECTION_ACTIVE';
const BOOK_SECTION_LIST__PUSH_SECTION_LIST_DATA = 'BOOK_SECTION_LIST__PUSH_SECTION_LIST_DATA';
const BOOK_SECTION_LIST__CHANGE_LOADING = 'BOOK_SECTION_LIST__CHANGE_LOADING';
const BOOK_SECTION_LIST__CHANGE_PAGE_END = 'BOOK_SECTION_LIST__CHANGE_PAGE_END';
const BOOK_SECTION_LIST__CHANGE_SECTION_DATA = 'BOOK_SECTION_LIST__CHANGE_SECTION_DATA';

const BANNER__PUSH_BANNER_DATA = 'BANNER__PUSH_BANNER_DATA';

const BOOK_TYPE__LOAD_TYPE_LIST = 'BOOK_TYPE__LOAD_TYPE_LIST';
const BOOK_TYPE__CHANGE_ACTIVE = 'BOOK_TYPE__CHANGE_ACTIVE';
const BOOK_TYPE__CHANGE_LOADING = 'BOOK_TYPE__CHANGE_LOADING';
const BOOK_TYPE__LOAD_ARTICLE_LIST = 'BOOK_TYPE__LOAD_ARTICLE_LIST';

const RANK_LIST__LOAD_ARTICLE_LIST = 'RANK_LIST__LOAD_ARTICLE_LIST';
const RANK_LIST__CHANGE_ACTIVE = 'RANK_LIST__CHANGE_ACTIVE';
const RANK_LIST__CHANGE_LOADING = 'RANK_LIST__CHANGE_LOADING';

const HOT_UPDATE__CHANGE_LOADING = 'HOT_UPDATE__CHANGE_LOADING';
const HOT_UPDATE__LOAD_ARTICLE_LIST = 'HOT_UPDATE__LOAD_ARTICLE_LIST';

const FULL__CHANGE_LOADING = 'FULL__CHANGE_LOADING';
const FULL__LOAD_ARTICLE_LIST = 'FULL__LOAD_ARTICLE_LIST';

export {
    TITLE_BAR__CHANGE_BAR,
    BOOK_SECTION_LIST__CHANGE_SECTION_ACTIVE,
    BOOK_SECTION_LIST__PUSH_SECTION_LIST_DATA,
    BOOK_SECTION_LIST__CHANGE_LOADING,
    BOOK_SECTION_LIST__CHANGE_PAGE_END,
    BOOK_SECTION_LIST__CHANGE_SECTION_DATA,
    BANNER__PUSH_BANNER_DATA,
    BOOK_TYPE__LOAD_TYPE_LIST,
    BOOK_TYPE__CHANGE_ACTIVE,
    BOOK_TYPE__CHANGE_LOADING,
    BOOK_TYPE__LOAD_ARTICLE_LIST,
    RANK_LIST__LOAD_ARTICLE_LIST,
    RANK_LIST__CHANGE_ACTIVE,
    RANK_LIST__CHANGE_LOADING,
    HOT_UPDATE__CHANGE_LOADING,
    HOT_UPDATE__LOAD_ARTICLE_LIST,
    FULL__CHANGE_LOADING,
    FULL__LOAD_ARTICLE_LIST
};
