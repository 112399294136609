import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';

import './Full.scss';

import ScrollLoad from '-/common/js/scroll';
import Loading from '../Loading/Loading';
import { changeLoading, loadArticleList } from '../store/dispatch/full';
import SectionArticleItem from '../BookSectionList/SectionArticleItem/SectionArticleItem';

class Full extends Component {
    constructor (props) {
        super(props);
    }

    render () {
        const { articleList, pageEnd, loading } = this.props;
        return (
            <div className="full">
                {articleList.map((v) => {
                    return (
                        <SectionArticleItem key={v.id} article={v}/>
                    );
                })}
                <Loading pageEnd={pageEnd} loading={loading}/>
            </div>
        );
    }

    componentDidMount () {
        if (this.scroll) {
            return;
        }

        const nextPage = this.props.page + 1;

        this.scroll = new ScrollLoad({
            scrollArea: window,
            loadOffset: 30,
            initPage: nextPage,
            autoLoad: nextPage === 1,
            loadFun: (currentPage, me) => {
                this.props.changeLoading(true);
                this.props.loadArticleList(currentPage, me);
            }
        });

        if (this.props.pageEnd) {
            this.scroll.lock();
        }
    }

    componentWillUnmount () {
        this.scroll && this.scroll.removeScrollEvent();
    }
}

const stateMap = (state) => {
    return {
        loading: state.fullReducer.loading,
        hasLoad: state.fullReducer.hasLoad,
        articleList: state.fullReducer.articleList,
        pageEnd: state.fullReducer.pageEnd,
        page: state.fullReducer.page
    };
};

const eventMap = (dispatch) => {
    return {
        changeLoading (status) {
            dispatch(changeLoading(status));
        },
        loadArticleList (page, scroll) {
            dispatch(loadArticleList(page, scroll));
        }
    };
};

Full.propTypes = {
    changeLoading: PropType.func.isRequired,
    hasLoad: PropType.bool.isRequired,
    articleList: PropType.array.isRequired,
    loadArticleList: PropType.func.isRequired,
    pageEnd: PropType.bool.isRequired,
    loading: PropType.bool.isRequired,
    page: PropType.number.isRequired
};

export default connect(stateMap, eventMap)(Full);
