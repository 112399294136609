import { combineReducers } from 'redux';
import titleBarReducer from '../TitleBar/store/reducers';
import bannerReducer from '../Banner/store/reducers';
import itemListReducer from '../ItemList/store/reducers';
import sectionReducer from '../BookSectionList/store/reducers';
import bookTypeReducer from '../BookType/store/reducers';
import rankListReducer from '../RankList/store/reducers';
import hotUpdateReducer from '../HotUpdate/store/reducers';
import fullReducer from '../Full/store/reducers';

const reducers = combineReducers({
    titleBarReducer: titleBarReducer,
    bannerReducer: bannerReducer,
    itemListReducer: itemListReducer,
    sectionReducer: sectionReducer,
    bookTypeReducer: bookTypeReducer,
    rankListReducer: rankListReducer,
    hotUpdateReducer: hotUpdateReducer,
    fullReducer: fullReducer
});

export default reducers;
