import {
    FULL__CHANGE_LOADING,
    FULL__LOAD_ARTICLE_LIST
} from '../../store/actionType';

const initState = {
    loading: false,
    hasLoad: false,
    articleList: [],
    pageEnd: false,
    page: 0
};

const changeLoading = (state, action) => {
    state.loading = action.data;
    return state;
};

const loadArticleList = (state, action) => {
    if (action.page === 1) {
        state.articleList = [];
    }
    state.articleList = state.articleList.concat(action.data);
    state.hasLoad = true;
    state.loading = false;
    state.pageEnd = action.pageEnd;
    state.page = action.page;
    return state;
};

export default (state = initState, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
    case FULL__CHANGE_LOADING:
        return changeLoading(newState, action);
    case FULL__LOAD_ARTICLE_LIST:
        return loadArticleList(newState, action);
    default:
        return state;
    }
};
