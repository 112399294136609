import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import '-/common/css/reset.css';
import '-/common/css/common.css';

import '-/common/js/rem';

import store from './store/index';
import Main from './Main/Main';

import '../interceptors';

import VConsole from 'vconsole';
import { Url } from 'lif-lib';

const url = new Url(window.location.href);
const vcFlag = url.get('vconsole');

if (parseInt(vcFlag) === 1) {
    // eslint-disable-next-line no-unused-vars
    const vConsole = new VConsole();
}

ReactDOM.render(
    <Provider store={store}>
        <Main/>
    </Provider>,
    document.getElementById('root')
);
