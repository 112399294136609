import React, { Component } from 'react';
import PropType from 'prop-types';

import config from '../../../config';
import * as bridge from '../../../bridge';
import './RowArticle.scss';

class RowArticle extends Component {
    constructor (props) {
        super(props);

        this.bookCover = React.createRef();
        this.state = {
            imageStatus: false
        };
        this.unmount = false;
    }

    render () {
        const article = this.props.article;

        return (
            <div className="article-item" onClick={RowArticle.handleClick.bind(this, article.id, article.name)}>
                <div className="article-item-left">
                    {this.renderArticleStatus(article.status)}
                    {this.renderBookCover(article.image, article.name)}
                </div>
                <div className="article-item-right">
                    <p className="article-name">{article.rank}.{article.name}</p>
                    <p className="article-description">{article.description}</p>
                    <div className="right-bottom">
                        <div className="article-author iconfont">{article.author}</div>
                        <div className="article-type">{article.typeName}</div>
                    </div>
                </div>
            </div>
        );
    }

    UNSAFE_componentWillMount () {
        const { article } = this.props;
        const img = new Image();
        img.src = article.image;
        img.onload = () => {
            if (this.unmount) {
                return;
            }
            this.setState({
                imageStatus: true,
                img: img
            });
        };
    }

    componentWillUnmount () {
        this.unmount = true;
    }

    renderBookCover (coverUrl, articleName) {
        if (!this.state.imageStatus) {
            coverUrl = config.loadingImage;
        }

        return <img ref={this.bookCover} onError={this.handleErrorImage.bind(this)} src={coverUrl} className="article-item-img" alt={articleName}/>;
    }

    static handleClick (articleId, articleName) {
        const href = window.location.origin + '/details.html#/' + articleId;
        bridge.openWindow(href, articleName, 0, 1, '0xF6F6F6');
    }

    handleErrorImage () {
        const imgDom = this.bookCover.current;
        if (imgDom) {
            imgDom.src = config.defaultImage;
        }
    }

    /**
     * 渲染小说状态, 0 => 连载, 1 => 完结
     * @param status
     */
    renderArticleStatus (status) {
        if (status) {
            return <div className="article-status status-finish">完结</div>;
        } else {
            return <div className="article-status status-unfinished">连载</div>;
        }
    }
}

RowArticle.propTypes = {
    article: PropType.object.isRequired
};

export default RowArticle;
