import { TITLE_BAR__CHANGE_BAR } from '../../store/actionType';

const initState = {
    barList: [
        { id: 1, name: '精选', href: '#/handpick', className: 'handpick', bindSection: 1 },
        { id: 2, name: '男生', href: '#/boy', className: 'boy', bindSection: 2 },
        { id: 3, name: '女生', href: '#/girl', className: 'girl', bindSection: 3 }
        // { id: 4, name: '全本', href: '#/full', className: 'full', bindSection: 5 }
    ],
    activeId: 1
};

const changeBar = (state, action) => {
    return { ...state, activeId: action.activeId };
};

export default (state = initState, action) => {
    switch (action.type) {
    case TITLE_BAR__CHANGE_BAR :
        return changeBar(state, action);
    default:
        return state;
    }
};
