import React, { Component } from 'react';
import './RowArticleItem.scss';
import PropType from 'prop-types';
import config from '../../../config';
import * as bridge from '../../../bridge';

class RowArticleItem extends Component {
    constructor (props) {
        super(props);

        this.bookCover = React.createRef();
        this.state = {
            imageStatus: false
        };
        this.unmount = false;
    }

    UNSAFE_componentWillMount () {
        const { article } = this.props;
        const img = new Image();
        img.src = article.image;
        img.onload = () => {
            if (this.unmount) {
                return;
            }
            this.setState({
                imageStatus: true
            });
        };
    }

    render () {
        const article = this.props.article;
        return (
            <div className="section-article-item template-2" key={article.id}>
                <a onClick={RowArticleItem.handleClick.bind(this, article.id, article.name)}>
                    <div className="article-item-img-box">
                        <div>
                            {this.renderArticleStatus(article.status)}
                            {this.renderBookCover(article.image, article.name)}
                        </div>
                    </div>
                    <p className="article-item-name">{article.name}</p>
                </a>
            </div>
        );
    }

    renderBookCover (coverUrl, articleName) {
        if (!this.state.imageStatus) {
            coverUrl = config.loadingImage;
        }

        return <img ref={this.bookCover} onError={this.handleErrorImage.bind(this)} src={coverUrl} className="article-item-img" alt={articleName}/>;
    }

    handleErrorImage () {
        const imgDom = this.bookCover.current;
        if (imgDom) {
            imgDom.src = config.defaultImage;
        }
    }

    static handleClick (articleId, articleName) {
        const href = window.location.origin + '/details.html#/' + articleId;
        bridge.openWindow(href, articleName, 0, 1, '0xF6F6F6');
    }

    /**
     * 渲染小说状态, 0 => 连载, 1 => 完结
     * @param status
     */
    renderArticleStatus (status) {
        if (status) {
            return <div className="article-status status-finish">完结</div>;
        } else {
            return <div className="article-status status-unfinished">连载</div>;
        }
    }

    componentWillUnmount () {
        this.unmount = true;
    }
}

RowArticleItem.propTypes = {
    article: PropType.object.isRequired
};

export default RowArticleItem;
