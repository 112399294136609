import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    changeLoading,
    changeSection,
    changeSectionActive,
    pushSectionListData
} from '../store/dispatch/bookSectionList';

import ScrollLoad from '-/common/js/scroll';
import Loading from '../Loading/Loading';
import BookSection from '../BookSection/BookSection';

class BookSectionList extends Component {
    constructor (props) {
        super(props);

        this.currentItem = this.props.titleBar.find((item) => {
            if (item.id === this.props.type) {
                return item;
            }
            return null;
        });

        this.sectionType = this.currentItem.bindSection;

        const page = this.props.sectionReducer[this.sectionType].nextPage;
        this.scroll = new ScrollLoad({
            scrollArea: window,
            loadOffset: 30,
            initPage: page,
            autoLoad: page === 1,
            loadFun: (currentPage, me) => {
                this.props.changeLoading(true, this.sectionType);
                this.props.pushSectionListData(currentPage, me, this.sectionType, this.props.category);
            }
        });

        if (this.props.sectionReducer[this.sectionType].pageEnd) {
            this.scroll.lock();
        }
    }

    componentDidMount () {

    }

    componentWillUnmount () {
        this.scroll && this.scroll.removeScrollEvent();
    }

    render () {
        const sectionType = this.props.sectionReducer[this.sectionType];
        const sectionList = sectionType.sectionList || [];
        const { pageLoading, pageEnd } = sectionType;
        console.log(`pageLoading:${pageLoading}, end: ${pageEnd}, scroll is state: ${this.scroll.isReady}`);

        return (
            <Fragment>
                <div className="book-section-list">
                    {
                        sectionList.map((section, index) => {
                            return <BookSection key={index} section={section} onClick={ section.id === 0 ? null : this.activeSection.bind(this, index, true, section.id)}/>;
                        })
                    }
                    <Loading loading={pageLoading} pageEnd={pageEnd}/>
                </div>
            </Fragment>
        );
    }

    activeSection (index, status, id) {
        this.props.activeSection(index, status, this.sectionType);
        this.props.changeSection(id, index, this.sectionType);
    }
}

const stateMap = (state) => {
    return {
        titleBar: state.titleBarReducer.barList,
        sectionReducer: state.sectionReducer
    };
};

const eventMap = (dispatch) => {
    return {
        activeSection (index, status, sectionType) {
            if (!this.sectionReducer[sectionType].sectionList.btn_active) {
                dispatch(changeSectionActive(index, true, sectionType));
            }
        },
        pushSectionListData (loadPage, me, sectionType, category) {
            dispatch(pushSectionListData(loadPage, me, sectionType, category));
        },
        changeLoading (loading, sectionType) {
            dispatch(changeLoading(loading, sectionType));
        },
        changeSection (sectionId, index, sectionType) {
            dispatch(changeSection(sectionId, index, sectionType));
        }
    };
};

BookSectionList.propTypes = {
    titleBar: PropTypes.array.isRequired,
    activeSection: PropTypes.func.isRequired,
    pushSectionListData: PropTypes.func.isRequired,
    changeLoading: PropTypes.func.isRequired,
    changeSection: PropTypes.func.isRequired,
    sectionReducer: PropTypes.object.isRequired,
    type: PropTypes.number.isRequired,
    category: PropTypes.number.isRequired
};

export default connect(stateMap, eventMap)(BookSectionList);
