import React, { Component } from 'react';

import { Route, HashRouter, Switch } from 'react-router-dom';

import Handpick from '../Handpick/Handpick';
import Boy from '../Boy/Boy';
import Girl from '../Girl/Girl';
import Full from '../Full/Full';
import BookType from '../BookType/BookType';
import RankList from '../RankList/RankList';
import HotUpdate from '../HotUpdate/HotUpdate';

class Main extends Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <div>
                <HashRouter>
                    <Switch>
                        <Route path="/handpick" exact component={Handpick}/>
                        <Route path="/boy" exact component={Boy}/>
                        <Route path="/girl" exact component={Girl}/>
                        <Route path="/full" exact component={Full}/>
                        <Route path="/typelist" exact component={BookType}/>
                        <Route path="/ranklist" exact component={RankList}/>
                        <Route path="/hotupdate" exact component={HotUpdate}/>
                        <Route path="/full" exact component={Full}/>
                    </Switch>
                </HashRouter>
            </div>
        );
    }
}

export default Main;
