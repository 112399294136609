import React, { Fragment, Component } from 'react';
import BookSection from '../BookSectionList/BookSectionList';
import { connect } from 'react-redux';
import Loading from '../Loading/Loading';
import PropType from 'prop-types';
import './Girl.scss';
import Banner from '../Banner/Banner';
import * as bridge from '../../bridge';

class Girl extends Component {
    constructor (props) {
        super(props);
        this.type = 3;
    }

    render () {
        console.log('loading: ' + this.props.section[this.type].loading);
        const loading = this.props.section[this.type].loading;
        return (
            <Fragment>
                { loading
                    ? <div className="girl-full-mask">
                        <Loading pageEnd={false} loading={true}/>
                    </div>
                    : ''}
                <Banner type={this.type}/>
                <BookSection type={this.type} category={2}/>
            </Fragment>
        );
    }

    componentDidMount () {
        bridge.sliderArea(
            parseInt(window.innerWidth / 10 * 0.26667),
            parseInt(window.innerWidth / 10 * 3.86667));
    }
};

const stateMap = (state) => {
    return {
        section: state.sectionReducer
    };
};

const eventMap = (dispatch) => {
    return {
    };
};

Girl.propTypes = {
    section: PropType.object.isRequired
};

export default connect(stateMap, eventMap)(Girl);
