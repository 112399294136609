import axios from 'axios';
import config from '../../../config';
import { BANNER__PUSH_BANNER_DATA } from '../actionType';

export const pushBannerData = (type) => {
    return async (dispatch) => {
        try {
            const reps = await axios.post(config.getBannerUrl, {
                sectionId: type
            });
            const { code, data, message } = reps.data;

            if (code !== 0) {
                console.error(message);
                return;
            }

            dispatch({
                type: BANNER__PUSH_BANNER_DATA,
                data: data
            });
        } catch (e) {
            console.error(e);
        }
    };
};
