import { BANNER__PUSH_BANNER_DATA } from '../../store/actionType';

const initState = {
    bannerList: [],
    hasLoad: false
};

const pushBannerData = (state, action) => {
    state.bannerList = [];
    state.bannerList = action.data.map((v) => {
        return {
            img: v.image,
            href: v.href,
            id: v.id
        };
    });
    state.hasLoad = true;
    return state;
};

export default (state = initState, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
    case BANNER__PUSH_BANNER_DATA:
        return pushBannerData(newState, action);
    default:
        return state;
    }
};
