import {
    RANK_LIST__CHANGE_ACTIVE,
    RANK_LIST__CHANGE_LOADING,
    RANK_LIST__LOAD_ARTICLE_LIST
} from '../../store/actionType';

const initState = {
    // 1 = 阅读榜, 2 = 收藏榜, 3 = 热搜榜, 4 = 飙升榜, 5 = 新书榜 , 6 = 官方推荐榜'
    rankType: [
        { id: 1, name: '阅读榜' },
        { id: 2, name: '收藏榜' },
        { id: 3, name: '热搜榜' },
        { id: 4, name: '飙升榜' },
        { id: 5, name: '新书榜' },
        { id: 6, name: '推荐榜' }
    ],
    loading: false,
    hasLoad: false,
    articleList: [],
    pageEnd: false,
    active: 0,
    page: 0
};

const changeActive = (state, action) => {
    state.active = action.id;
    return state;
};

const changeLoading = (state, action) => {
    state.loading = action.data;
    return state;
};

const loadArticleList = (state, action) => {
    if (action.page === 1) {
        state.articleList = [];
    }
    state.articleList = state.articleList.concat(action.data);
    state.hasLoad = true;
    state.loading = false;
    state.pageEnd = action.pageEnd;
    state.page = action.page;
    state.active = parseInt(action.rankType);
    return state;
};

export default (state = initState, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
    case RANK_LIST__CHANGE_ACTIVE:
        return changeActive(newState, action);
    case RANK_LIST__CHANGE_LOADING:
        return changeLoading(newState, action);
    case RANK_LIST__LOAD_ARTICLE_LIST:
        return loadArticleList(newState, action);
    default:
        return state;
    }
};
