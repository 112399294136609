import {
    BOOK_SECTION_LIST__CHANGE_LOADING, BOOK_SECTION_LIST__CHANGE_PAGE_END,
    BOOK_SECTION_LIST__CHANGE_SECTION_ACTIVE, BOOK_SECTION_LIST__CHANGE_SECTION_DATA,
    BOOK_SECTION_LIST__PUSH_SECTION_LIST_DATA
} from '../actionType';
import axios from 'axios';
import config from '../../../config';

export const changeSectionActive = (index, status, sectionType) => {
    return {
        type: BOOK_SECTION_LIST__CHANGE_SECTION_ACTIVE,
        index: index,
        status: status,
        sectionType: sectionType
    };
};

let LoadMode = 1;

export const pushSectionListData = (loadPage, scroll, sectionType, category) => {
    return async (dispatch) => {
        try {
            let pageEnd = false;
            const temp = [];
            const extTemp = [];
            if (LoadMode === 1) {
                const reps = await axios.post(config.getSectionListUrl, {
                    page: loadPage,
                    sectionType: sectionType
                });

                console.log('%O', reps);

                const {
                    code,
                    data,
                    message
                } = reps.data;

                if (code !== 0) {
                    console.error(message);
                    return;
                }

                if (data.length === 3) {
                    scroll.ready();
                    pageEnd = false;
                    loadPage++;
                } else {
                    scroll.ready();
                    // scroll.lock();
                    // pageEnd = true;
                    LoadMode = 2;
                    loadPage = 1;
                    scroll.page = 1;
                }

                temp.push(...data);
            } else {
                const reps = await axios.post(config.getUserLatelyLikeUrl, {
                    page: loadPage,
                    category: category
                });

                const {
                    code,
                    data,
                    message
                } = reps.data;

                if (code !== 0) {
                    console.error(message);
                    return;
                }

                if (loadPage === 1) {
                    const newSection = {
                        articleList: [],
                        id: 0,
                        orderId: 0,
                        templateId: 1,
                        title: '其他用户在看',
                        type: 1
                    };

                    temp.push(newSection);
                }

                if (data.length < 10) {
                    scroll.lock();
                    pageEnd = true;
                } else {
                    scroll.ready();
                    pageEnd = false;
                    loadPage++;
                }

                extTemp.push(...data);
            }

            console.log('temp %O', temp);

            dispatch({
                type: BOOK_SECTION_LIST__PUSH_SECTION_LIST_DATA,
                data: temp,
                page: loadPage,
                sectionType: sectionType,
                pageEnd,
                extData: extTemp,
                loadMode: LoadMode
            });
        } catch (e) {
            console.error(e);
        }
    };
};

export const changeLoading = (loading, sectionType) => {
    return {
        type: BOOK_SECTION_LIST__CHANGE_LOADING,
        loading: loading,
        sectionType: sectionType
    };
};

export const changePageEnd = (pageEnd, sectionType) => {
    return {
        type: BOOK_SECTION_LIST__CHANGE_PAGE_END,
        pageEnd: pageEnd,
        sectionType: sectionType
    };
};

export const changeSectionData = (data, index, active, sectionType) => {
    return {
        type: BOOK_SECTION_LIST__CHANGE_SECTION_DATA,
        articleList: data,
        index: index,
        sectionType: sectionType,
        active: active
    };
};

export const changeSection = (sectionId, index, sectionType) => {
    return async (dispatch) => {
        try {
            const reps = await axios.post(config.getSectionRandomUrl, {
                sectionId: sectionId
            });

            const {
                code,
                data,
                message
            } = reps.data;

            if (code !== 0) {
                console.error(message);
                return;
            }

            dispatch(changeSectionData(data.articleList, index, false, sectionType));
        } catch (e) {
            console.error(e);
        }
    };
};
