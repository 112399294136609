import fl from '../images/fl.png';
import ph from '../images/ph.png';
import rg from '../images/rg.png';
import wb from '../images/wb.png';

const initState = {
    itemList: [
        { id: 1, name: '分类', href: '#/typelist', icon: fl },
        { id: 2, name: '排行', href: '#/ranklist', icon: ph },
        { id: 3, name: '热更', href: '#/hotupdate', icon: rg },
        { id: 4, name: '完本', href: '#/full', icon: wb }
    ]
};

export default (state = initState, action) => {
    switch (action.type) {
    default:
        return state;
    }
};
